<template>
  <div class="game-container">
    <TwitterGame :gameList="gameList"  :buttonList="buttonList"/>
  </div>
</template>

<script>
import TwitterGame from "@/components/Course/GamePage/TwitterGame";
export default {
  data() {
    return {
      gameList: [
        {
          originImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-1-1.svg"),
          clickImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-1-2.svg"),
          wordsImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-1-3.svg"),
          heartImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-1-4.svg"),
        },
        {
          originImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-2-1.svg"),
          clickImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-2-2.svg"),
          wordsImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-2-3.svg"),
          heartImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-2-4.svg"),
        },
         {
          originImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-3-1.svg"),
          clickImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-3-2.svg"),
          wordsImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-3-3.svg"),
          heartImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-3-4.svg"),
        },
        {
          originImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-4-1.svg"),
          clickImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-4-2.svg"),
          wordsImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-4-3.svg"),
          heartImg:require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/phone-4-4.svg"),
        }
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
         {
          image: require("@/assets/img/16-GAMES/G60-phone/l2-lesson-01/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    TwitterGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
