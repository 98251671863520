<template>
  <div class="story-page-one-container" @click="handleClick">
    <StoryPage :bgImg="bgImg_1" v-if="currentIndex === 0" />
    <StoryPage :bgImg="bgImg_2" v-if="currentIndex === 1" />
  </div>
</template>

<script>
import StoryPage from "@/components/Course/CoursePage/StoryPage";
export default {
  name: "StoryPageOne",
  components: {
    StoryPage,
  },
  data() {
    return {
      currentIndex: 0,
      bgImg_1: require("@/assets/img/13-Video/chapter-01/lesson-1-video-1-1.svg"),
      bgImg_2: require("@/assets/img/13-Video/chapter-01/lesson-1-video-1-2.svg"),
    };
  },
  methods: {
    handleClick() {
      if (this.currentIndex >= 1) {
        this.currentIndex = 1;
        return;
      }
      this.currentIndex++;
    },
  },
};
</script>

<style lang="scss" scoped>
.story-page-one-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
<!-- <template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/0-bulb-on.svg"),
        firstImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/1-bulb-on.svg"),
        secondImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/2-bulb-on.svg"),
        thirdImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/3-bulb-on.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/hengzhegou-white.svg"),
      strokePinyin: "héngzhégōu",
      gameIndex: 7,
      lightIndex: [1, 2, 4],
      hanziStrokeInfo: [
        {
          pinyin: "men",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/men-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/men-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "zài",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/zai-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/zai-1.svg"),
              isShow: false,
              index: 2,
            },
          ],
        },
        {
          pinyin: "péng",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/peng-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/peng-1.svg"),
              isShow: false,
              index: 3,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/peng-2.svg"),
              isShow: false,
              index: 4,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style> -->