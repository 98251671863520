<template>
  <div class="game-container">
    <ClickImgShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :title="title"
      :noFirstImg="true"
      :lessonNO="65"
    />
  </div>
</template>

<script>
import ClickImgShowSentenceGame from "@/components/Course/GamePage/ClickImgShowSentenceGame";
export default {
  data() {
    return {
      title:{
        pinyin:"Kěyǐ … ma?",
        hanzi:"可以……吗？"
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/apple.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/apple.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/milk.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/milk.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/cake.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/cake.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/clock.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/clock.svg"),
          id: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/table.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/table.svg"),
          id: 5,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/apple-story.svg"),
          id: 1,
          centencePinyin: "Wǒ kěyǐ chī píngguǒ ma？",
          sentenceHanzi: "我可以吃苹果吗？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/milk-story.svg"),
          id: 2,
          centencePinyin: "Wǒ kěyǐ hē niúnǎi ma？",
          sentenceHanzi: "我可以喝牛奶吗？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/cake-story.svg"),
          id: 3,
          centencePinyin: "Wǒmen kěyǐ chī dàngāo ma？",
          sentenceHanzi: "我们可以吃蛋糕吗",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/table-story.svg"),
          id: 4,
          centencePinyin: "Xiànzài kěyǐ qù gōngyuán ma？",
          sentenceHanzi: "现在可以去公园吗？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-65/clock-story.svg"),
          id: 5,
          centencePinyin: "Wǒ kěyǐ zuò zhèr ma？",
          sentenceHanzi: "我可以坐这儿吗？",
        },
      ],
    };
  },
  components: {
    ClickImgShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











