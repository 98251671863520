<template>
  <div class="game-container">
    <PracticeGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :chooseHanziList="chooseHanziList"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import PracticeGame from "@/components/Course/GamePage/PracticeGame";
export default {
  data() {
    return {
      answerList: [2, 3, 3, 1,4,3],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      titleInfo: {
        hanzi: "请......",
        pinyin: "qǐng",
      },
      allImgList: [
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-1.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-2.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-3.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-5.svg`),
              id: 4,
            },
          ]
        },
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-4.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-6.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-7.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-8.svg`),
              id: 4,
            },
          ]
        },
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-2.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-4.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-6.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-8.svg`),
              id: 4,
            },
          ]
        },
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-1.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-4.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-5.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-3.svg`),
              id: 4,
            },
          ]
        },
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-3.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-1.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-6.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-8.svg`),
              id: 4,
            },
          ]
        },
        {
          imgArr:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-4.svg`),
              id: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-2.svg`),
              id: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-5.svg`),
              id: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-img/image-7.svg`),
              id: 4,
            },
          ]
        },
      ],
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-2.svg`),
          audioSrc: require("@/assets/audio/chapter_1/ni-hao.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-7.svg`),
          audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
    
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-12.svg`),
          audioSrc: require("@/assets/audio/chapter_1/laoshi-hao.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-15.svg`),
          audioSrc: require("@/assets/audio/chapter_1/wo-shi-dongdong.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-20.svg`),
          audioSrc: require("@/assets/audio/chapter_1/wo-shi-dongdong.mp3"),
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-50/image-23.svg`),
          audioSrc: require("@/assets/audio/chapter_1/wo-shi-dongdong.mp3"),
        },
      ],
    };
  },
  computed: {
  },
  components: {
    PracticeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
<!-- <template>
  <div class="game-container">
    <RecognitionPinyinGame :optionList="optionList" :imgList="imgList" :backgroundSize="`cover`" :isFullScreen="true" />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      optionList: [
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G65-img/image-2.svg"),
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G65-img/image-1.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G65-img/image-4.svg"),
        }
      ],

      imgList: [
        {
          id: 1,
          pinying: "qǐngzuò",
          hanzi: "请坐！",
        },
        {
          id: 2,
          pinying: "qǐngkàn",
          hanzi: "请看！",
        },
        {
          id: 3,
          pinying: "qǐngheshuǐ",
          hanzi: "请喝水！",
        }
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style> -->
