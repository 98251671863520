<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-65.mp3"),
      },
      timeStampList: [7.6, 14.4, 28.5, 35.5, 44],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "wǒmen kěyǐ zuò zhèr ma？",
            chineseWords: "我们可以坐这儿吗？",
          },
          {
            id: 2,
            spelling: "Kěyǐ,kěyǐ,qǐng zuò！",
            chineseWords: "可以，可以，请坐！",
          },
          {
            id: 3,
            spelling: "Zhè shì nǐmen de dà dàngāo.",
            chineseWords: "这是你们的大蛋糕。",
          },
          {
            id: 4,
            spelling: "xièxie nǐ ！xièxie nǐ ！",
            chineseWords: "谢谢你！谢谢你！",
          },
          {
            id: 5,
            spelling: "bú kèqi！bú kèqi！",
            chineseWords: "不客气，不客气！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
