<template>
  <div class="practice-container">
    <PracticePage :practiceList="practiceList" :buttonList="buttonList"/>
  </div>
</template>
<script>
import PracticePage from "@/components/Course/CoursePage/PracticePage";
export default {
  data() {
    return {
      practiceList: [
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-1-1.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-1-2.svg"),
              isRight: true,
            },
          ],
          hanzi: "坐",
          pinyin: "zuò",
          status: 2,
          // 0 无操作 1 正确 2 失败
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-2-1.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-2-2.svg"),
              isRight: false,
            },
          ],
          hanzi: "坐",
          pinyin: "zuò",
          status: 0,
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-3-1.svg"),
              isRight: true,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-3-2.svg"),
              isRight: false,
            },
          ],
          hanzi: "坐",
          pinyin: "zuò",
          status: 0,
        },
        {
          imgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-4-1.svg"),
              isRight: false,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G02-right-wrong/zuo-4-2.svg"),
              isRight: true,
            },
          ],
          hanzi: "坐",
          pinyin: "zuò",
          status: 0,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-2.svg"),
          startNumberRange: 3,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-3.svg"),
          startNumberRange: 5,
          endNumberRange: 6,
        },
        {
          image: require("@/assets/img/16-GAMES/G02-right-wrong/menu-4.svg"),
          startNumberRange: 7,
          endNumberRange: 8,
        },
      ],
    };
  },
  components: {
    PracticePage,
  },
};
</script>
<style lang="scss" scoped>
.practice-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>