var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-page-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "dialog-page-wrapper" },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c(
            "div",
            { staticClass: "dialog-content", class: "lesson-" + _vm.lessonNo },
            _vm._l(_vm.pageInfoList, function (item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentIndex === item.index,
                      expression: "currentIndex === item.index",
                    },
                  ],
                  key: index,
                  staticClass: "page-item",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bubble-item",
                      class: { allScreen: _vm.isAllScreen },
                    },
                    [
                      !_vm.isAllScreen
                        ? _c("div", { staticClass: "bg-img-area" }, [
                            _c("img", { attrs: { src: item.bgImg, alt: "" } }),
                          ])
                        : _vm._e(),
                      _vm._l(item.singlePageList, function (ele, indexSon) {
                        return _c(
                          "div",
                          {
                            key: indexSon + "son",
                            ref: "bubbleArea",
                            refInFor: true,
                            staticClass: "bubble-area",
                            class: "bubble-" + ele.id,
                            style: { top: _vm.imgTopPos + `px` },
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in" } },
                              [
                                !ele.isShowAnser
                                  ? _c("img", {
                                      staticClass: "question moveUpHover",
                                      class: {
                                        scaleToAppear: _vm.fadeInElements,
                                        isHidden: _vm.hideElement,
                                      },
                                      attrs: { src: ele.bubbleQuestion },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickBubble(ele)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "el-fade-in" } },
                              [
                                ele.isShowAnser
                                  ? _c("img", {
                                      staticClass: "answer",
                                      attrs: { src: ele.bubbleAnswer },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }