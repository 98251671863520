<template>
  <div class="click-img-to-show-sentence-game layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="optionList.length"
        :currentIndex="currentStarIndex"
      />
      <div class="right-text" v-if="title">
        <span class="font-text txt-py font-pinyin-medium">{{title.pinyin}}</span>
        <span class="txt-hz font-hanzi-medium">{{title.hanzi}}</span>
      </div>
      <div class="title-area" v-if="titleInfo" :class="'lesson-' + lessonNO">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="background-area">
        <img :src="bgImg" />

        <template v-for="(item, index) in optionList">
          <transition name="el-fade-in" :key="index + 'bg'">
            <img
              :src="item.isShowCorrect ? item.clickImg : item.bgImg"
              class="bg-img-item"
              :class="{ fistImgOpacity: (!item.isShowCorrect && noFirstImg) || (item.isShowCorrect && !noFirstImg) }"
            />
          </transition>
        </template>
      </div>

      <div class="click-area">
        <div
          v-for="(item, index) in optionList"
          :key="index + 'click'"
          :class="'click-area-' + item.id"
          @click="handleClickImg(item.id)"
        />
      </div>

      <div v-if="isShowMask == true" @click="isShowMask = false" class="mask-area">
        <div
          class="mask-item"
          v-for="(item, index) in maskImgList"
          :key="index + 'mask'"
          v-show="currentId === item.id"
        >
          <div class="mask-img">
            <img :src="item.img" alt="" v-if="item.img" />
          </div>

          <div class="sentence" v-if="item.sentenceHanzi">
            <p class="pinyin font-pinyin-medium">{{ item.centencePinyin }}</p>
            <p class="font-hanzi-medium">{{ item.sentenceHanzi }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "ClickImgToShowSentenceGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: true,
    },
    optionList: {
      type: Array,
      require: true,
    },
    maskImgList: {
      type: Array,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    noFirstImg: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      idList: [],
      currentId: 0,
      currentStarIndex: 0,
      isShowMask: false,
      isShowSentence: false,

      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      idSocketInfo: {},
      clickSocketInfo: {},
    };
  },
  components: {
    Star,
    PageButton,
  },
  watch: {
    idSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006001,
          data: { value },
          text: "SpeakingSentence点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: "SpeakingSentence点击Img socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("getIdSocket", ({ id }) => {
      this.handleClickImg(id, true);
    });
    // this.$bus.$on("showSentenceSocket", () => {
    //   this.handleShowSentence("socket", true);
    // });
  },
  beforeDestroy() {
    this.$bus.$off("getIdSocket");
    // this.$bus.$off("showSentenceSocket");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.idSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.currentId = id;
      if (this.idList.indexOf(this.currentId) > -1) {
        return;
      }
      this.idList.push(this.currentId);
      this.isShowMask = true;
      this.$emit("showCoeerctImg", this.currentId);
      this.currentStarIndex++;
      if (this.currentStarIndex === this.optionList.length) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      } else {
        playCorrectSound(true, false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.click-img-to-show-sentence-game {
  background: #fff;
  border-radius: 48px;
  .game-content {
    width: 100%;
    height: 100%;
    // background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
      }
      .fistImgOpacity {
        opacity: 0;
      }
    }
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .click-area-1 {
        display: block;
        position: absolute;
        width: 23%;
        height: 37%;
        top: 7%;
        left: 9%;
        z-index: 5;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-2 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 19%;
        height: 20%;
        top: 50%;
        left: 90%;
        &:hover {
          cursor: pointer;
        }
      }

      .click-area-3 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 8%;
        height: 12%;
        top: 40%;
        left: 52%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-4 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-5 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-6 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .right-text {
      background-color: #224E96;
      border-top-right-radius: 29px;
      border-bottom-right-radius: 29px;
      position: absolute;
      top: 6%;
      left: 0;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      flex-flow: column;
      z-index: 9;
      width: auto;
      height: auto;
      padding: 3%;
    }
    .title-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 30%;
      height: 17%;
      background: #224E96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .lesson-65 {
      width: 20%;
    }
    .mask-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.1);
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .mask-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .mask-img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 58px;
          }
        }
        .sentence {
          position: absolute;
          // width: 70%;
          text-align: center;
          background: #224E96;
          padding: 15px 6%;
          color: #fff;
          border-radius: 20px;
          bottom: 8%;
          margin: auto 0;
        }
        .showSentence {
          opacity: 1;
        }
      }
    }
  }
  .lesson-65 {
    .click-area {
      .click-area-1 {
        width: 11%;
        height: 11%;
        top: 47%;
        left: 3%;
      }
      .click-area-2 {
        width: 10%;
        height: 17%;
        top: 40%;
        left: 82%;
      }
      .click-area-3 {
        width: 11%;
        height: 10%;
        top: 30%;
        left: 21%;
      }
      .click-area-4 {
        width: 17%;
        height: 17%;
        top: 12%;
        left: 31%;
      }
      .click-area-5 {
        width: 27%;
        height: 23%;
        top: 70%;
        left: 7%;
      }
    }
  }
}
</style>
