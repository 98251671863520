<template>
  <div class="lesson-container">
    <DialogPageOne
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :lessonNo="65"
    />
  </div>
</template>

<script>
import DialogPageOne from "@/components/Course/CoursePage/DialogPageOne";
export default {
  data() {
    return {
      clickIdList:[1,3,6,8],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-65/menu-1.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-65/menu-2.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-65/menu-3.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-65/menu-4.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-65/mask.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-1-xiexie.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-65/image-2.svg"),
          singlePageList: [
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-1-xiexie.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-question.svg"),
              isShowAnser: false,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-65/image-3.svg"),
          singlePageList: [
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              isShowAnser: false,
            },
            {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-1-xiexie.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 4,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-65/image-4.svg"),
          singlePageList: [
            {
              id: 7,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-2-bukeqi.svg"),
              isShowAnser: false,
            },
            {
              id: 8,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-1-xiexie.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-65/bubble-question.svg"),
              isShowAnser: false,
            },
          ],
        },
      ],
    };
  },
  components: {
    DialogPageOne,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>