<template>
  <div class="dialog-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="dialog-page-wrapper">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="dialog-content" :class="'lesson-' + lessonNo">
        <div
          class="page-item"
          v-for="(item, index) in pageInfoList"
          :key="index"
          v-show="currentIndex === item.index"
        >
         
          <div class="bubble-item" :class="{ allScreen: isAllScreen }">
            <div class="bg-img-area" v-if="!isAllScreen">
              <img :src="item.bgImg" alt="" />
            </div>
            <div
              class="bubble-area"
              :style="{top: imgTopPos + `px`}"
              ref="bubbleArea"
              v-for="(ele, indexSon) in item.singlePageList"
              :key="indexSon + 'son'"
              :class="'bubble-' + ele.id"
            >
              <transition name="el-fade-in" >
                <img
                  :src="ele.bubbleQuestion"
                  v-if="!ele.isShowAnser"
                  class="question moveUpHover"
                  :class="{scaleToAppear : fadeInElements, isHidden : hideElement}"
                  @click="handleClickBubble(ele)"
                />
              </transition>
              <transition name="el-fade-in">
                <img
                  :src="ele.bubbleAnswer"
                  class="answer"
                  v-if="ele.isShowAnser"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  name: "DialogPage",
  props: {
    pageInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    clickIdList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNo: {
      type: Number,
      require: true,
    },
    isAllScreen: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    SceneSwitchThumbnails,
    PageButton,
  },
  data() {
    return {
      numBers: 0,
      currentIndex: 1,
      currentIdlist: [],
      isLastStep: false,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),

      clickBubbleSocketInfo: {},
      clickMenuSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    imgTopPos:0,
    fadeInElements: false,
    hideElement: true,
    };
  },
  watch: {
    // 发送
    clickBubbleSocketInfo: {
      handler(value) {
        // 测试socket
        const data = {
          clickType: 30001,
          data: { value },
          text: "introductoion页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        // 测试socket
        const data = {
          clickType: 3000101,
          data: { value },
          text: "introductoion页面侧边菜单栏按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  mounted() {
    // 接收
    this.$bus.$on("clickIntroSteps", ({ item }) => {
      this.handleClickBubble(item, true);
    });
    this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    window.addEventListener("resize", this.updateElementPosition);
    let _this = this;
    setTimeout(function(){_this.updateElementPosition();_this.fadeInElements = true;_this.hideElement = false;setTimeout(function(){_this.fadeInElements = false;}, 300)}, 300);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateElementPosition);
  },
  beforeDestroy() {
    this.$bus.$off("clickIntroSteps");
    this.$bus.$off("clickIntroAside");
  },
  methods: {
    updateElementPosition(){
      if ( undefined !== document.querySelectorAll('.bg-img')[this.currentIndex-1]) {
        this.imgTopPos = document.querySelectorAll('.bg-img')[this.currentIndex-1].offsetTop
      }
    },
    changeTheNumbers(index, isFromSocket = false) {
      this.hideElement = true;
      this.fadeInElements = false;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.currentIndex = index;
      let _this = this;
      setTimeout(function(){
        _this.updateElementPosition();
        setTimeout(function(){
            _this.fadeInElements = true;_this.hideElement = false;setTimeout(function(){_this.fadeInElements = false;},300)},
            200)
      }, 100);

    },
    handleClickBubble(item, isFromSocket = false) {
      console.log(item,77788)
      if (!isFromSocket) {
        this.clickBubbleSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      if (this.clickIdList.indexOf(item.id) !== -1) {
        this.currentIdlist.push(item.id);
        this.currentIdlist = Array.from(new Set(this.currentIdlist));
        this.$emit("clickTheBubble", this.currentIndex, item.id);
        if (this.currentIdlist.length === this.clickIdList.length) {
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        } else {
          playCorrectSound(true, false);
        }
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-page-container {
  .dialog-page-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: auto;
      height: auto;
      padding:20px;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .dialog-content {
      width: 100%;
      height: 100%;
      z-index: 3;
      .page-item {
        width: 100%;
        height: 100%;
        .bubble-item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          border-radius: 60px;
          position: relative;
          .bg-img-area {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 58px;
            }
          }
          .bubble-area {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              z-index: 6;
              &.question {
                cursor: pointer;
              }
            }
          }
        }
        .allScreen {
          width: 100%;
          height: 100%;
          .bg-img-area {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 58px;
            }
          }
        }
      }
    }
    .lesson-65 {
      .bubble-1 {
        .question {
          top: 31%;
          right: 59%;
          width: 14%;
        }
        .answer {
          top: 31%;
          right: 60%;
          width: 21%;
        }
      }
      .bubble-2 {
        .question {
          left: 45%;
          top: 7%;
          width: 21%;
        }
        .answer {
          left: 50%;
          top: 11%;
          width: 22%;
        }
      }
      .bubble-3 {
        .question {
          left: 16%;
          top: 29%;
          width: 16%;
        }
        .answer {
          left: 7%;
          top: 29%;
          width: 21%;
        }
      }
      .bubble-4 {
        .question {
          left: 32%;
          top: 5%;
          width: 21%;
        }
        .answer {
          left: 65%;
          top: -6%;
          width: 35%;
        }
      }
      .bubble-5 {
        .question {
          left: 8%;
          top: 8%;
          width: 22%;
        }
        .answer {
          left: 13%;
          top: 5%;
          width: 35%;
        }
      }
      .bubble-6 {
        .question {
          left: 57%;
          top: 24%;
          width: 17%;
        }
        .answer {
          left: 50%;
          top: 23%;
          width: 25%;
        }
      }
      .bubble-7 {
        .question {
          left: 11%;
          top: 26%;
          width: 22%;
        }
        .answer {
          left: 13%;
          top: 5%;
          width: 35%;
        }
      }
      .bubble-8 {
        .question {
          left: 51%;
          top: 0%;
          width: 18%;
        }
        .answer {
          left: 42%;
          top: 2%;
          width: 24%;
        }
      }
    }
  }
}
</style>
