<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "kě yǐ",
              hanzi: "可以",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "qǐng ",
              hanzi: "请",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "zuò",
              hanzi: "坐",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "bú kè qì",
              hanzi: "不客气",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            
              // {
              //   pinyin: "nǐ",
              //   hanzi: "你",
              //   symbol: "",
              //   id: 1,
              //   symbolType: 0,
              // },

              // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

             
            [
              {
                pinyin: "jùxíng",
                hanzi: "句型",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "kě yǐ",
                hanzi: "可以",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "\xa0",
                hanzi: "......",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "ma",
                hanzi: "吗",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "？",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 7,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 7,
              },
              {
                pinyin: "biǎo shì",
                hanzi: "表示",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "qǐng qiú",
                hanzi: "请求",
                symbol: "",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "wǒ",
                hanzi: "我",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
             {
                pinyin: "kě yǐ",
                hanzi: "可以",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "zuò",
                hanzi: "坐",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: " zhèr ",
                hanzi: "这儿",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "ma",
                hanzi: "吗",
                symbol: "",
                group: 1,
                line: 2,
                index: 6,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: "？",
                group: 1,
                line: 2,
                index: 7,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "wǒ",
              hanzi: "我",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "huì yòng",
              hanzi: "会用",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "zhōng wén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "jiǎn dān de",
              hanzi: "简单地",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "biǎo dá",
              hanzi: "表达",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "qǐng qiú",
              hanzi: "请求",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "hé",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },

            {
              pinyin: "gǎn xiè",
              hanzi: "感谢",
              group: 2,
              index: 7,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>