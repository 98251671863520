<!-- <template>
  <div class="summary-container">
    <SummaryPage
      :summaryTitle="summaryTitle"
      :summaryList="summaryList"
      :lessonNum="1"
    />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  data() {
    return {
      summaryTitle: {
        pinyin: "Nǐ hǎo! Wǒ shì……",
        hanzi: "你好！我是……",
      },
      summaryList: [
        {
          pinyin: "Dōngdōng",
          hanzi: "冬冬",
          // imgUrl: require("@/assets/img/16-GAMES/title-dongdong.svg"),
          bgColor: 1,
        },
        {
          pinyin: "Xiǎo Ài",
          hanzi: "小爱",
          // imgUrl: require("@/assets/img/16-GAMES/title-xiaoai.svg"),
          bgColor: 2,
        },
        {
          pinyin: "BB",
          hanzi: "BB",
          // imgUrl: require("@/assets/img/16-GAMES/title-bb.svg"),
          bgColor: 3,
        },
      ],
    };
  },
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style> -->
<template>
  <div class="summary-container">
    <SummaryPage :lessonNo="1" />
  </div>
</template>

<script>
import SummaryPage from "@/components/Course/CoursePage/SummaryPage";
export default {
  components: {
    SummaryPage,
  },
};
</script>

<style lang="scss" scoped>
.summary-container {
  width: 100%;
  height: 100%;
}
</style>

